import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, addDoc, serverTimestamp, getDocs } from 'firebase/firestore';

const ReportForm: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [reportText, setReportText] = useState<string>('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  useEffect(() => {
    // Load the Cloudflare Turnstile widget
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.onload = () => {
      (window as any).turnstile.render('#captcha-container', {
        sitekey: process.env.REACT_APP_CAPTCHA_SITEKEY, // Load sitekey from .env
        callback: (token: string) => setCaptchaToken(token), // Captures the token
      });
    };
    document.body.appendChild(script);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    // Check if the CAPTCHA was solved
    if (!captchaToken) {
      alert('Please complete the CAPTCHA');
      return;
    }
  
    try {
      await addDoc(collection(db, 'stureports'), {
        name: name,
        email: email,
        reportText: reportText,
        reportStatus: 'active',
        reportedAt: serverTimestamp(), // Store server timestamp
        reportedBy: name,
      });
  
      // Clear form
      setName('');
      setEmail('');
      setReportText('');
      alert('Report submitted successfully!');
    } catch (error) {
      console.error('Error adding report: ', error);
      alert('Error submitting report. Please try again later.');
    }
  };

  const convertToMalaysiaTime = (timestamp: any) => {
    if (!timestamp) return null; // Return null if timestamp is undefined or null
    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
    return date.toLocaleString('en-US', { timeZone: 'Asia/Kuala_Lumpur' });
  };

  // Example function to fetch reports and convert timestamp
  const fetchReports = async () => {
    const querySnapshot = await getDocs(collection(db, 'stureports'));
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const formattedTime = convertToMalaysiaTime(data.reportedAt);
      console.log(`Report from ${data.name}: ${data.reportText} - Reported at: ${formattedTime}`);
    });
  };

  return (
    <div
      className="min-h-screen py-10 flex items-center justify-center"
      style={{
        backgroundImage: `url('https://tronova.azmiproductions.com/img/backy.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="relative z-10 flex items-center justify-center h-full">
        <div className="bg-gray-900 bg-opacity-70 shadow-lg rounded-lg p-8 max-w-md w-full mx-4 md:mx-auto transform transition-transform duration-500 hover:scale-105">
          <div className="flex justify-center mb-6 mt-6">
            <img
              src="https://tronova.azmiproductions.com/img/versewhite.png"
              alt="Your Account"
              className="w-24 h-24 rounded-full shadow-lg border-4 border-gray-700 object-cover"
            />
          </div>
          <h2 className="text-2xl font-bold text-white text-center mb-8">IIUM Shuttle Report</h2>

          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <label className="block text-white font-semibold mb-2" htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                className="w-full p-3 border-b-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 hover:border-blue-400"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="mb-5">
              <label className="block text-white font-semibold mb-2" htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                className="w-full p-3 border-b-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 hover:border-blue-400"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="mb-5">
              <label className="block text-white font-semibold mb-2" htmlFor="reportText">Report</label>
              <textarea
                id="reportText"
                className="w-full p-3 border-b-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 hover:border-blue-400"
                value={reportText}
                onChange={(e) => setReportText(e.target.value)}
                rows={4}
                required
              />
            </div>

            {/* Cloudflare Turnstile CAPTCHA */}
            <div className="mb-5" id="captcha-container"></div>

            <button
              type="submit"
              className="w-full mb-6 bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-md shadow-lg transition duration-300 transform hover:scale-105 hover:shadow-xl"
            >
              Submit Report
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReportForm;
