// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAT9ps5CYFv___A2NSrwPMULpGgrJGeYyw",
    authDomain: "shuttlebusiium.firebaseapp.com",
    projectId: "shuttlebusiium",
    storageBucket: "shuttlebusiium.appspot.com",
    messagingSenderId: "147845763016",
    appId: "1:147845763016:web:b795cc8865a86dd3803afd",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
